<template>
  <div class="app_srv">
    <div class="page_srv">
      <div class="back_container_srv" @click="GoBack()">
        <div class="back_ico_srv">
          <q-icon name="arrow_back" size="20px"> </q-icon>
          <div class="back_txt_srv">Back</div>
        </div>
      </div>
      <div v-for="row in rows.slice(findex, lindex)" :key="row.id">
        <p class="p1_srv">
          {{ row.title }}
        </p>
        <div class="entry-content">
          <div class="left_container_srv">
            <div class="completed_data">
              <p class="p2_srv">
                <span v-html="row.text"></span>
              </p>
            </div>
          </div>
          <div v-if="checkLength(row.image)" class="right_container_srv">
            <img
              :src="row.image"
              :key="row.rowId"
              class="img_stl"
              @click="visibleLightBox()"
            />
          </div>
          <div v-if="!checkLength(row.image)" class="right_container_srv">
            <div style="width: 100%; height: 350px" />
          </div>
        </div>
      </div>
      <div class="paginate_div">
        <paginate
          class="paginate_class"
          :page-count="this.pageCount"
          :margin-pages="2"
          :page-range="3"
          :click-handler="clickCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref } from "vue";
import servicesService from "../services/servicesService";
import { useStore } from "vuex";
import Paginate from "vuejs-paginate-next";

export default {
  routername: "Service",
  components: { Paginate },
  setup() {},
  data() {
    return {
      service: "",
      categ1: "",
      categ2: "",
      rows: ref([]),
      svisibleLightBox: false,
      images: ref([]),
      page: 1,
      pageCount: 0,
      findex: 0,
      lindex: 5,
    };
  },
  mounted() {
    const route = useRoute();
    this.service = route.params.routername;

    this.fetchData();
  },
  methods: {
    clickCallback(pageNum) {
      if (this.page < pageNum) {
        this.findex += (pageNum - this.page) * 5;
        this.lindex += (pageNum - this.page) * 5;
      } else {
        this.findex -= (this.page - pageNum) * 5;
        this.lindex -= (this.page - pageNum) * 5;
      }

      this.page = pageNum;
      window.scrollTo(0, 0);
    },
    fetchData() {
      const store = useStore();
      store.dispatch("show");
      var array = this.service.split("&");
      this.categ1 = array[0];
      this.categ2 = array[1];

      servicesService
        .get(this.categ1, this.categ2)
        .then((response) => {
          this.rows = response.data;
          this.pageCount = this.rows.length / 5;
          
          const lang = localStorage.getItem("lang");
          
          for (var i = 0; i < this.rows.length; i++) {
            this.images[i] = this.rows[i].image;
            if (lang == "en") {
              this.rows[i].text = this.rows[i].text_en;
              this.rows[i].title = this.rows[i].title_en;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            store.dispatch("hide");
          }, 500);
        });
    },
    hideSpinner() {
      useStore().dispatch("hide");
    },
    visibleLightBox() {
      this.svisibleLightBox = true;
    },
    handleHide() {
      this.svisibleLightBox = false;
    },
    checkLength(string) {
      if (string == null) {
        return false;
      }
      if (string.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    GoBack() {
      this.$router.push("/Services");
    },
  },
};
</script>

<style>
.app_srv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 260px 50px 260px;
  background: url("/uploads/wbp-content/wbp-background1.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

.page_srv {
  background-color: #ffff;
  position: relative;
  font-family: "Arial";
  padding: 70px 50px 70px 50px;
  width: 100%;
}

.back_container_srv {
  display: flex;
  align-items: center;
}

.back_container_srv :hover {
  cursor: pointer;
  color: #00afea;
  transition: color 0.3s;
}

.back_ico_srv {
  display: flex;
  align-items: center;
}

.back_txt_srv {
  font-size: 17px;
}

.img_stl {
  background-size: cover;
  width: 100%;
  height: 350px;
}

.entry-content {
  display: flex;
  height: auto;
}

.completed_data {
  margin-bottom: 60px;
}

.left_container_srv {
  flex: 0;
  flex-basis: calc(60% - 10px);
  max-width: calc(60% - 10px);
  position: relative;
}

.right_container_srv {
  flex: 0;
  flex-basis: calc(40% - 15px);
  max-width: calc(40% - 15px);
  position: relative;
  padding-left: 25px;
  text-align: left;
}

.panel2 {
  background-size: cover;
  min-width: 100%;
  min-height: 350px;
}

.p1_srv {
  font-size: 25px;
  text-align: left;
  font-weight: 300;
  color: black;
  font-family: "Arial";
  padding-top: 30px;
  min-width: 100%;
}

.p2_srv {
  font-size: 20px;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-top: 10px;
}

@media screen and (max-width: 1550px) {
  .app_srv {
    padding: 0 160px 10px 160px;
  }
}

@media screen and (max-width: 1330px) {
  .app_srv {
    padding: 0 60px 10px 60px;
  }
}

@media screen and (max-width: 1130px) {
  .app_srv {
    padding: 0 30px 10px 30px;
  }
  .p1_srv {
    font-size: 18px;
  }
  .p2_srv {
    font-size: 15px;
  }
}

@media screen and (max-width: 1070px) {
  .app_srv {
    padding: 0 10px 5px 10px;
  }
  .page_srv {
    padding: 30px 25px 30px 25px;
  }
}

@media screen and (max-width: 980px) {
  .app_srv {
    padding: 0;
    display: unset;
  }

  .page_srv {
    padding: 30px 10px 30px 10px;
  }

  .entry-content {
    display: flex;
    flex-direction: column-reverse;
  }

  .left_container_srv {
    max-width: 100%;
  }

  .right_container_srv {
    max-width: 100%;
    padding: 0;
  }
}
</style>
