<template>
  <div class="q-pa-md">
    <div class="phca_header_container">
      <div class="phca_add_btn">
        <q-btn
          class="phca_btn_style"
          color="light-blue-9"
          label="Add"
          icon="add"
          @click="onAddClick"
        />
      </div>
      <div class="phca_search">
        <q-input
          class="phca_search_style"
          bg-color="white"
          rounded
          outlined
          v-model="SearchText"
          label="Search"
        />
      </div>
    </div>
    <q-table
      title="Carousel"
      :loading="loading"
      :rows="rows"
      :columns="columns"
      row-key="id"
      @row-click="onRowClick"
      :rows-per-page-options="[10]"
      :filter="SearchText"
    >
      <template #loading>
        <q-inner-loading showing color="primary" />
      </template>
    </q-table>
  </div>

  <q-dialog
    v-model="dialogvalue"
    transition-show="scale"
    transition-hide="scale"
    no-esc-dismiss
    no-backdrop-dismiss
  >
    <q-card
      class="my-card"
      style="width: 560px; height: auto; background-color: #f5f5f5"
    >
      <img
        v-if="ImageActive"
        :src="imgsrc"
        :key="selectedItem.rowId"
        style="height: 400px"
      />
      <img
        v-if="!ImageActive"
        style="height: 400px"
        :src="'/uploads/wbp-content/noImg.png'"
      />
      <q-btn
        v-close-popup
        push
        color="red-5"
        round
        icon="close"
        class="absolute"
        style="z-index: 99; top: 0%"
        @click="fetchPhotos()"
      />
      <q-card-section>
        <q-btn
          class="bg-teal text-white"
          push
          label="Upload"
          icon="file_upload"
          @click="onPickFile"
        />
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          accept="image/*"
          @change="onFilePicked"
        />
        <div class="row no-wrap items-center">
          <q-input
            v-model.number="selectedItem.id"
            label="Id"
            stack-label
            type="number"
            disable
            readonly
          />
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="row no-wrap items-center">
          <q-input
            v-model="selectedItem.name"
            label="Name"
            maxlength="128"
            stack-label
            style="width: 100%"
          />
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="row no-wrap items-center">
          <q-input
            v-model.number="selectedItem.priority"
            label="Priority"
            type="number"
            stack-label
          />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn
          v-close-popup
          push
          color="light-blue-9"
          label="Submit"
          icon="done"
          @click="onSubmit"
        />
        <q-btn
          v-close-popup
          push
          color="red"
          label="Delete"
          icon="delete"
          @click="onDelete"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import carouselService from "../../services/carouselService";
import { ref } from "vue";
export default {
  name: "PhotoCarousel",
  //components: {ImagePopup},
  setup() {
    const rows = ref([]);
    const loading = ref(false);
    const ImageActive = ref(false);
    const selectedItem = ref({});
    const imgChange = null;
    const imgsrc = ref("");
    const fileExt = ref("");
    const SearchText = ref("");
    const dialogvalue = ref(false);
    return {
      columns: [
        {
          name: "id",
          align: "left",
          label: "Id",
          field: "id",
          sortable: true,
        },
        {
          name: "name",
          align: "left",
          label: "Name",
          field: "name",
          sortable: true,
        },
        {
          name: "priority",
          align: "left",
          label: "Priority",
          field: "priority",
          sortable: true,
        },
      ],
      rows,
      loading,
      ImageActive,
      selectedItem,
      imgChange,
      imgsrc,
      fileExt,
      SearchText,
      dialogvalue,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.fetchPhotos();
  },
  created() {},
  methods: {
    async fetchPhotos() {
      // this.rows =await carouselService.getAll().data
      this.loading = true;

      carouselService
        .getAll()
        .then((response) => {
          this.rows = response.data;
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(evt, row) {
      this.selectedItem = row;
      this.imgsrc = row.image;
      const imgactive = this.imgsrc;
      if (imgactive != null && imgactive.length > 0) {
        this.ImageActive = true;
      } else {
        this.ImageActive = false;
      }
      this.imgChange = null;
      this.dialogvalue = true;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file!");
      }
      this.fileExt = filename.split(".").pop();
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.addEventListener("load", () => {
        this.imgChange = fileReader.result;
        this.imgsrc = fileReader.result;
      });
    },
    onSubmit() {
      if (this.imgChange != null && this.imgChange != "") {
        this.selectedItem.imagebytes = this.imgChange.substr(
          this.imgChange.lastIndexOf("base64,") + 7,
          this.imgChange.length
        );
        this.selectedItem.image_ext = this.fileExt;
      } else {
        this.selectedItem.imagebytes = null;
        this.selectedItem.image_ext = null;
      }
      this.selectedItem.priority = parseInt(this.selectedItem.priority);

      carouselService
        .create(this.selectedItem)
        .then((response) => {})
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
          this.fetchPhotos();
        });
    },
    onAddClick() {
      this.selectedItem = ref({});
      this.imgChange = ref("");
      this.imgsrc = ref("");
      this.ImageActive = false;
      this.dialogvalue = true;
    },
    onDelete() {
      this.loading = true;
      carouselService
        .delete(this.selectedItem.id)
        .then((response) => {})
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
          this.fetchPhotos();
        });
    },
  },
};
</script>
<style scoped>
.phca_header_container {
  display: flex;
  padding: 10px 0 10px;
  width: 100%;
}
.phca_add_btn {
  display: flex;
  width: 50%;
  align-items: center;
}
.phca_btn_style {
  height: 30px;
}
.phca_search {
  display: flex;
  width: 50%;
}
.phca_search_style {
  width: 300px;
  margin-left: auto;
  margin-right: 0;
}
</style>
