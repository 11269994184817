<template>
  <div class="app">
    <div class="page">
      <div class="title">
        Όλες οι υπηρεσίες
        <hr class="hr" />
      </div>
      <p class="p1">Μελέτες</p>
      <hr class="hr" />
      <div class="entry-content">
        <div class="left-container">
          <div class="completed_data">
            <p class="p2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
              voluptatibus tempora omnis nihil delectus nesciunt libero ex
              impedit doloremque ea corrupti fuga molestias id, soluta ipsam
              reiciendis nobis? Quidem, corrupti.
            </p>
            <div class="list-pad">
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link1)">
                  Αρχιτεκτονική μελέτη και επίβλεψη νέων κατασκευών
                </div>
              </div>
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link2)">
                  Στατικές μελέτες
                </div>
              </div>
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link3)">
                  Μελέτες ενεργειακής απόδοσης
                </div>
              </div>
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link4)">
                  Νομιμοποιήσεις αυθαιρέτων
                </div>
              </div>
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link5)">
                  Πιστοποιητικά ενεργειακής απόδοσης (ΠΕΑ)
                </div>
              </div>
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link6)">
                  Τοπογραφικά
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="panel2" />
        </div>
      </div>
      <p class="p1">Κατασκευές</p>
      <hr class="hr" />
      <div class="entry-content">
        <div class="left-container">
          <div class="completed_data">
            <p class="p2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
              voluptatibus tempora omnis nihil delectus nesciunt libero ex
              impedit doloremque ea corrupti fuga molestias id, soluta ipsam
              reiciendis nobis? Quidem, corrupti.
            </p>
            <div class="list-pad">
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link7)">
                  Κατασκευές ιδιωτικών έργων
                </div>
              </div>
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link8)">
                  Κατασκευές Δημοσίων έργων
                </div>
              </div>
              <div class="data_div">
                <div class="company_data">•</div>
                <div class="input_data" @click="openLink(link9)">
                  Ανακαινίσεις
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="panel2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    store.dispatch("show");
    setTimeout(() => {
      store.dispatch("hide");
    }, 500);
  },
  data() {
    return {
      link1: "1&1&αρχιτεκτονική-μελέτη-και-επίβλεψη-νέων-κατασκευών",
      link2: "1&2&στατικές-μελέτες",
      link3: "1&3&μελέτες-ενεργειακής-απόδοσης",
      link4: "1&4&νομιμοποιήσεις-αυθαιρέτων",
      link5: "1&5&πιστοποιητικά-ενεργειακής-απόδοσης",
      link6: "1&6&τοπογραφικά",
      link7: "2&1&κατασκευές-ιδιωτικών-έργων",
      link8: "2&2&κατασκευές-δημοσίων-έργων",
      link9: "2&3&ανακαινίσεις",
    };
  },
  methods: {
    openLink(link) {
      this.$router.push("/Services/" + link);
    },
  },
};
</script>
<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 260px 50px 260px;
  background: url("/uploads/wbp-content/wbp-background1.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

.page {
  background-color: #ffff;
  position: relative;
  font-family: "Arial";
  padding: 70px 50px 70px 50px;
}

.entry-content {
  display: flex;
}

.left-container {
  flex: 0;
  flex-basis: calc(60% - 10px);
  max-width: calc(60% - 10px);
  position: relative;
}

.right-container {
  flex: 0;
  flex-basis: calc(40% - 15px);
  max-width: calc(40% - 15px);
  position: relative;
  padding-left: 25px;
  text-align: left;
}

.panel2 {
  background: url("/uploads/wbp-content/wpb-background2.jpg") no-repeat center;
  background-size: cover;
  min-width: 100%;
  min-height: 350px;
}

.completed_data {
  margin-bottom: 60px;
}

.data_div {
  clear: both;
  display: flex;
}

.company_data {
  float: left;
  font-size: 20px;
}

.input_data {
  float: left;
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
  margin-top: 1px;
  font-family: "Arial";
  text-align: left;
  overflow: hidden;
}

.input_data:hover {
  cursor: pointer;
  color: #fcd088;
  transition: color 0.3s;
}

.title {
  font-size: 30px;
  text-align: left;
  color: black;
}

.hr {
  background-color: lightgrey;
  color: lightgrey;
  height: 1px;
  width: 70%;
  border: 0;
  margin: 0 0;
}

.p1 {
  font-size: 20px;
  text-align: left;
  font-weight: 300;
  color: black;
  font-family: "Arial";
  padding-top: 30px;
  min-width: 100%;
}

.p2 {
  font-size: 20px;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-top: 10px;
}

.panel1 {
  width: 50%;
  display: relative;
}

.label-text {
  padding-top: 20px;
  font-family: "Arial";
  color: #be97a6;
  font-size: 18px;
}

.input_text {
  height: 40px;
  width: -webkit-fill-available;
  font-size: 17px;
  font-family: "Arial";
}

.message-text {
  width: 100%;
  font-size: 17px;
  font-family: "Arial";
  resize: none !important;
}

.list-pad {
  padding-top: 20px;
  overflow: auto;
}

.panel2 {
  background: url("/uploads/wbp-content/wpb-background2.jpg") no-repeat center;
  background-size: cover;
  width: 50%;
  height: 285px;
}

@media screen and (max-width: 1550px) {
  .app {
    padding: 0 160px 10px 160px;
  }
}

@media screen and (max-width: 1330px) {
  .app {
    padding: 0 60px 10px 60px;
  }
}

@media screen and (max-width: 1130px) {
  .app {
    padding: 0 30px 10px 30px;
  }
}

@media screen and (max-width: 1070px) {
  .app {
    padding: 0 10px 5px 10px;
  }
  .page {
    padding: 30px 25px 30px 25px;
  }
}

@media screen and (max-width: 980px) {
  .app {
    padding: 0;
    display: unset;
  }

  .page {
    padding: 30px 10px 30px 10px;
  }

  .entry-content {
    display: flex;
    flex-direction: column-reverse;
  }

  .left-container {
    max-width: 100%;
  }

  .right-container {
    max-width: 100%;
    padding: 0;
  }
}
</style>
