<template>
  <div class="footer">
    <div class="container">
      <div class="footer_col1">
        <img
          class="logo"
          src="@/assets/logo.png"
          alt=""
          @click="goToPage('/')"
        >
      </div>
      <div class="footer_col2">
        <div class="title">
          {{ $t("footer_aboutUs") }}
        </div>
        <div class="data_div_footer">
          <div
            class="input_data_footer"
            @click="goToPage('/Company')"
          >
            {{ $t("footer_companyPage") }}
          </div>
        </div>
        <div class="data_div_footer">
          <div
            class="input_data_footer"
            @click="goToPage('/Services')"
          >
            {{ $t("footer_servicesPage") }}
          </div>
        </div>
        <div class="data_div_footer">
          <div
            class="input_data_footer"
            @click="goToPage('/Contact')"
          >
            {{ $t("footer_contactPage") }}
          </div>
        </div>
        <div class="data_div_footer">
          <div
            class="input_data_footer"
            @click="goToPage('/Blogs')"
          >
            Blog
          </div>
        </div>
      </div>
      <div class="footer_col3">
        <div class="title">
          {{ $t("footer_contactTitle") }}
        </div>
        <div class="data_div_footer">
          <div class="company_data_footer">
            <q-icon name="location_on" />
          </div>
          <div
            class="contact_info"
            @click="openGoogleMap(Address)"
          >
            {{ Address }}
          </div>
        </div>
        <div class="data_div_footer">
          <div class="company_data_footer">
            <q-icon name="call" />
          </div>
          <div
            class="contact_info"
            @click="openPhone(Phone)"
          >
            {{ Phone }}
          </div>
        </div>
        <div class="data_div_footer">
          <div class="company_data_footer">
            <q-icon name="phone_iphone" />
          </div>
          <div
            class="contact_info"
            @click="openPhone(MobilePhone)"
          >
            {{ MobilePhone }}
          </div>
        </div>
        <div class="data_div_footer">
          <div class="company_data_footer">
            <q-icon name="email" />
          </div>
          <div
            class="contact_info"
            @click="openEmail(EmailAddress)"
          >
            {{ EmailAddress }}
          </div>
        </div>
      </div>
      <div class="footer_col4">
        <iframe
          loading="lazy"
          :src="'https://maps.google.com/maps?q='+Address+'%20athens&t=&z=13&ie=UTF8&iwloc=&output=embed'"
          frameborder="0"
          allowfullscreen
          style="border: 0px;"
          display:
          block
          width="100%"
          height="300px"
        />
      </div>
      <div class="bottom">
        <div class="developed">
          <div
            class="personal_info"
            @click="openlinkedin(linkedin_url)"
          >
            Developed by Panagiotis Papasotiriou
          </div>
          <img
            class="linked_in"
            src=".././assets/linkedin.png"
          >
        </div>
        <div class="copy_rights">
          Copyright © 2022 mi-architecture
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Address: "MICROSOFT ΕΛΛΑΣ Α.Β.Ε.Ε.",
      EmailAddress: "emailtest@gmail.com",
      Phone: "2111206000",
      MobilePhone: "6999999999",
      linkedin_url: "https://www.linkedin.com/in/panagiotis-papasotiriou-104b0a194/"
    };
  },
  methods: {
    openGoogleMap(text) {
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + text,
        "_blank"
      );
    },
    openEmail(EmailAddress) {
      window.location.href = "mailto:" + EmailAddress;
    },
    openPhone(Phone) {
      window.location.href = "tel:" + Phone;
    },
    openlinkedin(LinkedInUrl) {
      window.open(LinkedInUrl);
    },
    goToPage(string) {
      this.$router.push(string);
    },
    goToContact() {
      this.$router.push('/Contact');
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
.footer {
  background-color: #24262b;
  padding: 25px;
  text-align: left;
  z-index: 99;
  height: auto;  
}
.container {
  margin: auto;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
}
.data_div_footer {
  clear: both;
  display: flex;
  flex-direction: row;
}
.footer_col1 {
  width: 25%;
}
.footer_col4 {
  width: 35%;
}
.footer_col2,
.footer_col3 {
  width: 20%;
  height: 100%;
}

.title {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 500;
  position: left;
  font-family: "Arial";
  padding-bottom: 5px;
}
.company_data_footer {
  color: grey;
  font-size: 30px;
}
.contact_info {
  font-size: 14px;
  padding-left: 5px;
  margin-top: 12px;
  font-family: "Arial";
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}

.input_data_footer {
  font-size: 14px;
  margin-top: 12px;
  font-family: "Arial";
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}

.contact_info:hover {
  cursor: pointer;
  color: #ffffff;
  padding-left: 13px;
}

.input_data_footer:hover {
  cursor: pointer;
  color: #ffffff;
  padding-left: 8px;
}

.logo {
  display: flex;
  width: 350px;
  height: 100.21px;
  float: left;
}

.logo:hover {
  cursor: pointer;
}

.bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 20px;
}

.developed {
  width: 50%;
  color: #bbbbbb;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.personal_info {
  width: auto;
}

.personal_info:hover {
  cursor: pointer;
  color: #ffffff;
  transition: color 0.3s;
}

.linked_in {
  width: 25px;
  height: 25px;
  float: right;
  justify-content: center;
}

.copy_rights {
  width: 50%;
  color: #bbbbbb;
  text-align: right;
}

/*responsive*/
@media screen and (max-width: 1600px) {
  .footer_col1 {
    width: 40%;
  }
  .footer_col2,
  .footer_col3 {
    width: 30%;
  }
  .footer_col4 {
    width: 70%;
    padding-top: 20px;
  }
}

@media screen and (max-width: 1020px) {
  .footer_col1 {
    width: 50%;
  }
  .footer_col2 {
    width: 50%;
    align-items: center;
  }
  .footer_col3,
  .footer_col4 {
    padding-top: 20px;
    width: 70%;
  }
}

@media screen and (max-width: 800px) {
  .footer {
    padding: 5px;
  }
  .footer_col1 {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: unset;
  }
  .footer_col2,
  .footer_col3,
  .footer_col4 {
    padding-top: 20px;
    width: 100%;
  }
  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: unset;
  }
  .copy_rights {
    width: 100%;
    text-align: left;
  }
  .developed {
    width: 100%;
  }
  .bottom {
    flex-direction: column;
  }
}
</style>
