<template>
  <div class="q-pa-md">
    <div class="usr_header_container">
      <div class="usr_add_btn">
        <q-btn
          class="usr_btn_style"
          color="light-blue-9"
          label="Add"
          icon="add"
          @click="onAddClick"
        />
      </div>
      <div class="usr_search">
        <q-input
          class="usr_search_style"
          bg-color="white"
          rounded
          outlined
          v-model="SearchText"
          label="Search"
        />
      </div>
    </div>
    <q-table
      title="Users"
      :loading="loading"
      :rows="rows"
      :columns="columns"
      row-key="name"
      @row-click="onRowClick"
      :rows-per-page-options="[10]"
      :filter="SearchText"
    >
      <template #loading>
        <q-inner-loading showing color="primary" />
      </template>
    </q-table>
  </div>

  <q-dialog
    v-model="ImageActive"
    transition-show="scale"
    transition-hide="scale"
    no-esc-dismiss
    no-backdrop-dismiss
    maximized
  >
    <q-card class="usr_card">
      <div class="usr_close_btn">
        <q-btn
          v-close-popup
          push
          color="red-5"
          round
          icon="close"
          class="absolute"
          style="z-index: 99; top: 0%"
          @click="fetchUsers()"
        />
      </div>
      <div class="usr_main_data">
        <div class="usr_id_container">
          <q-input
            class="usr_id_input"
            outlined
            v-model.number="selectedItem.id"
            label="Id"
            stack-label
            type="number"
            disable
            readonly
          />
        </div>
        <div class="usr_username_container">
          <q-input
            class="usr_username_input"
            outlined
            v-model="selectedItem.userName"
            label="Username"
            maxlength="128"
            stack-label
            required
          />
        </div>
        <div class="usr_username_container">
          <q-input
            class="usr_username_input"
            outlined
            :type="isPwd ? 'password' : 'text'"
            v-model="selectedItem.password"
            label="Password"
            maxlength="128"
            stack-label
            aria-required="true"
            aria-invalid="false"
          >
            <template #append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </div>
        <div class="usr_username_container">
          <q-input
            class="usr_username_input"
            outlined
            v-model="selectedItem.email"
            label="Email"
            maxlength="128"
            stack-label
            required
          />
        </div>
        <div class="usr_insert_data">
          <div class="usr_insertuser_container">
            <q-input
              outlined
              v-model="selectedItem.insertUser"
              label="Insert User"
              disable
              readonly
              stack-label
              class="usr_insertuser_input"
            />
          </div>
          <div class="usr_insdate_container">
            <q-input
              class="usr_insdate_input"
              outlined
              v-model="selectedItem.insDate"
              label="Insert Date"
              mask="date"
              disable
              readonly
              stack-label
            />
          </div>
        </div>
        <div class="usr_update_data">
          <div class="usr_updateuser_container">
            <q-input
              class="usr_updateuser_input"
              outlined
              v-model="selectedItem.updateUser"
              label="Update User"
              disable
              readonly
              stack-label
            />
          </div>
          <div class="usr_upddate_container">
            <q-input
              class="usr_upddate_input"
              outlined
              v-model="selectedItem.updDate"
              label="Update Date"
              mask="date"
              disable
              readonly
              stack-label
            />
          </div>
        </div>
        <q-card-section>
          <div class="row no-wrap items-center"></div>
        </q-card-section>
        <q-separator />
        <q-card-actions class="usr_card_actions">
          <q-btn
            v-close-popup
            push
            color="light-blue-9"
            label="Submit"
            icon="done"
            @click="onSubmit"
          />
          <q-btn
            v-close-popup
            push
            color="red"
            label="Delete"
            icon="delete"
            @click="onDelete"
          />
        </q-card-actions>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import usersService from "../../services/usersService";
import { ref } from "vue";

export default {
  name: "Blog",
  setup() {
    const rows = ref([]);
    const loading = ref(false);
    const ImageActive = ref(false);
    const selectedItem = ref({});
    const imgChange = ref("");
    const description = ref("");
    const imgsrc = ref("");
    const fileExt = ref("");
    const SearchText = ref("");
    return {
      columns: [
        {
          name: "id",
          align: "left",
          label: "Id",
          field: "id",
          sortable: true,
        },
        {
          name: "Username",
          align: "left",
          label: "Username",
          field: "userName",
          sortable: true,
        },

        {
          name: "Email",
          align: "left",
          label: "Email",
          field: "email",
          sortable: true,
        },
      ],
      rows,
      loading,
      ImageActive,
      selectedItem,
      imgChange,
      description,
      imgsrc,
      fileExt,
      isPwd: ref(true),
      SearchText,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.fetchUsers();
  },
  created() {},
  methods: {
    async fetchUsers() {
      this.loading = true;

      usersService
        .getAll()
        .then((response) => {
          this.rows = response.data;
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(evt, row) {
      this.selectedItem = row;
      this.imgsrc = row.image;
      this.description = row.description;
      this.blogdate = row.blogdate;
      this.ImageActive = true;
      this.imgChange = null;
    },
    onSubmit() {
      this.selectedItem.description = this.description;

      usersService
        .create(this.selectedItem)
        .then((response) => {})
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
          this.fetchUsers();
        });
    },
    onAddClick() {
      this.selectedItem = ref({});
      this.description = ref("");
      this.imgChange = ref("");
      this.ImageActive = true;
      this.imgsrc = ref("");
    },
    onDelete() {
      this.loading = true;
      if(this.selectedItem.id == parseInt(this.$cookie.getCookie("user_id")))
      {
        alert("Caution! You cannot delete the user that you are logged in.");
        this.loading = false;
        this.fetchUsers();
        return;
      }

      usersService
        .delete(this.selectedItem.id)
        .then((response) => {})
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
          this.fetchUsers();
        });
    },
  },
};
</script>
<style scoped>
.usr_header_container {
  display: flex;
  padding: 10px 0 10px;
  width: 100%;
}
.usr_add_btn {
  display: flex;
  width: 50%;
  align-items: center;
}
.usr_btn_style {
  height: 30px;
}
.usr_search {
  display: flex;
  width: 50%;
}
.usr_search_style {
  width: 300px;
  margin-left: auto;
  margin-right: 0;
}
.usr_card {
  width: 600px;
  height: auto;
  background-color: #f5f5f5;
}
.usr_close_btn {
  height: 40px;
}
.usr_main_data {
  padding: 15px 40px 0;
}
.usr_id_container {
  width: 100%;
}
.usr_id_input {
  width: 120px;
  background-color: white;
}
.usr_username_container {
  width: 100%;
  padding-top: 35px;
}
.usr_username_input {
  width: 100%;
  background-color: white;
}
.usr_insert_data {
  width: 100%;
  padding-top: 96px;
  display: flex;
}
.usr_insertuser_container {
  width: 50%;
}
.usr_insertuser_input {
  width: 100%;
  background-color: white;
}
.usr_insdate_container {
  width: 50%;
  padding-left: 25px;
}
.usr_insdate_input {
  width: 100%;
  background-color: white;
}
.usr_update_data {
  width: 100%;
  padding-top: 35px;
  display: flex;
}
.usr_updateuser_container {
  width: 50%;
}
.usr_updateuser_input {
  width: 100%;
  background-color: white;
}
.usr_upddate_container {
  width: 50%;
  padding-left: 25px;
}
.usr_upddate_input {
  width: 100%;
  background-color: white;
}
.usr_card_actions {
  justify-content: right;
}
@media screen and (max-width: 900px) {
  .usr_card {
    width: 80%;
  }
}
@media screen and (max-width: 620px) {
  .usr_card {
    width: 100%;
  }

  .usr_insert_data,
  .usr_update_data {
    display: unset;
  }
  .usr_username_container,
  .usr_insertuser_container,
  .usr_insdate_container,
  .usr_updateuser_container,
  .usr_upddate_container {
    width: 100%;
    padding-top: 15px;
    padding-left: 0px;
  }
}
</style>
