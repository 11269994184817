<template>
  <div class="app">
    <div class="page">
      <div class="title">
        Επικοινωνία
        <hr class="hr" />
      </div>
      <div class="entry-content">
        <div class="left-container">
          <p class="p1">Στοιχεία επικοινωνίας</p>
          <div class="completed_data">
            <div class="data_div">
              <div class="company_data">
                <q-icon name="location_on" />
              </div>
              <div class="input_data" @click="openGoogleMap(Address)">
                {{ Address }}
              </div>
            </div>
            <div class="data_div">
              <div class="company_data">
                <q-icon name="call" />
              </div>
              <div class="input_data" @click="openPhone(Phone)">
                {{ Phone }}
              </div>
            </div>
            <div class="data_div">
              <div class="company_data">
                <q-icon name="phone_iphone" />
              </div>
              <div class="input_data" @click="openPhone(MobilePhone)">
                {{ MobilePhone }}
              </div>
            </div>
            <div class="data_div">
              <div class="company_data">
                <q-icon name="email" />
              </div>
              <div class="input_data" @click="openEmail(EmailAddress)">
                {{ EmailAddress }}
              </div>
            </div>
          </div>
          <div class="vce-google-maps-inner">
            <iframe loading="lazy"
            :src="'https://maps.google.com/maps?q='+Address+'%20athens&t=&z=13&ie=UTF8&iwloc=&output=embed'"
            width="100%" height="450" frameborder="0" style="border: 0px"
            allowfullscreen display: block />
          </div>
        </div>
        <div class="right-container">
          <p class="p1">Στείλτε το μήνυμα σας</p>
          <p class="p1">
            Μην διστάσετε να επικοινωνήσετε μαζί μας για να σας κάνουμε
            λεπτομερής ανάλυση των υπηρεσιών μας και να σας λύσουμε οποιαδήποτε
            απορία
          </p>
          <div class="label-text">Το όνομά σας (απαραίτητο)</div>
          <q-input
            v-model="this.email.sender_name"
            class="input_text"
            outlined
            bg-color="white"
            type="text"
            aria-required="true"
            aria-invalid="false"
          />
          <div class="label-text">Το email σας (απαραίτητο)</div>
          <q-input
            v-model="this.email.sender_email"
            class="input_text"
            outlined
            bg-color="white"
            type="text"
            aria-required="true"
            aria-invalid="false"
          />
          <div class="label-text">Θέμα (απαραίτητο)</div>
          <q-input
            v-model="this.email.title"
            class="input_text"
            outlined
            bg-color="white"
            type="text"
            aria-required="true"
            aria-invalid="false"
          />
          <div class="label-text">Το μήνυμά σας</div>
          <q-input
            v-model="this.email.body"
            class="message-text"
            outlined
            bg-color="white"
            type="textarea"
            rows="11"
          />
          <div class="btn-pad">
            <q-btn
              :disabled="disableSendButton"
              outline
              class="btn-send"
              label="Αποστολη"
              @click="sendEmail()"
            />
            <i class="icon ion-information-circled" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import sendEmailService from "../services/sendEmailService";

export default {
  setup() {
    const store = useStore();
    store.dispatch("show");
    setTimeout(() => {
      store.dispatch("hide");
    }, 500);
  },
  data() {
    return {
      Address: "MICROSOFT ΕΛΛΑΣ Α.Β.Ε.Ε.",
      EmailAddress: "emailtest@gmail.com",
      Phone: "2111206000",
      MobilePhone: "6999999999",
      disableSendButton: false,
      email: ref({}),
    };
  },
  methods: {
    showNotification(group, type, title) {
      this.disableSendButton = true;
      this.$notify({
        group,
        title,
        type,
      });
      setTimeout(() => {
        this.disableSendButton = false;
      }, 3000);
    },
    sendEmail() {
      if(!this.email.sender_name)
      {
        this.showNotification("foo-velocity", "error", "Παρακαλώ συμπληρώστε το όνομά σας");
        return;
      }

      if(!this.email.sender_email)
      {
        this.showNotification("foo-velocity", "error", "Παρακαλώ συμπληρώστε το email σας");
        return;
      }

      if(!this.email.title)
      {
        this.showNotification("foo-velocity", "error", "Παρακαλώ συμπληρώστε το Θέμα");
        return;
      }

      if(!this.email.body)
      {
        this.showNotification("foo-velocity", "error", "Παρακαλώ συμπληρώστε το μήνυμά σας");
        return;
      }

      sendEmailService
        .create(this.email)
        .then((response) => {
          this.reqdata = response.data;
          if (this.reqdata.success)
          {
            this.showNotification("foo-velocity", "success", this.reqdata.msg);
          }
          else
          {
            this.showNotification("foo-velocity", "error", this.reqdata.msg);
          }
        })
        .catch((e) => {
          this.showNotification("foo-velocity", "error", this.reqdata.msg);
        });
    },
    openGoogleMap(text) {
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + text,
        "_blank"
      );
    },
    openEmail(EmailAddress) {
      window.location.href = "mailto:" + EmailAddress;
    },
    openPhone(Phone) {
      window.location.href = "tel:" + Phone;
    },
  },
};
</script>
<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 260px 50px 260px;
  background: url("/uploads/wbp-content/wbp-background1.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

.page {
  background-color: #ffff;
  position: relative;
  font-family: "Arial";
  padding: 70px 50px 70px 50px;
}

.entry-content {
  display: flex;
}

.left-container {
  flex: 0;
  flex-basis: calc(50% - 10px);
  max-width: calc(50% - 10px);
  position: relative;
}

.right-container {
  flex: 0;
  flex-basis: calc(50% - 15px);
  max-width: calc(50% - 15px);
  position: relative;
  padding-left: 25px;
  text-align: left;
}

.completed_data {
  margin-bottom: 60px;
}

.data_div {
  clear: both;
}

.company_data {
  float: left;
  font-size: 30px;
  color: #fcd088;
}

.input_data {
  float: left;
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 12px;
  font-family: "Arial";
}

.input_data:hover {
  cursor: pointer;
  color: #fcd088;
  transition: color 0.3s;
}

.vce-google-maps-inner {
  height: auto;
  text-align: left;
}

.title {
  font-size: 30px;
  text-align: left;
  color: black;
}

.hr {
  background-color: lightgrey;
  color: lightgrey;
  height: 1px;
  width: 70%;
  border: 0;
  margin: 0 0;
}

.p1 {
  font-size: 20px;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-top: 20px;
}

.panel1 {
  width: 50%;
  display: relative;
}

.label-text {
  padding-top: 20px;
  font-family: "Arial";
  color: #be97a6;
  font-size: 18px;
}

.input_text {
  height: 40px;
  width: -webkit-fill-available;
  font-size: 17px;
  font-family: "Arial";
}

.message-text {
  width: 100%;
  font-size: 17px;
  font-family: "Arial";
  resize: none !important;
}

.btn-send {
  color: goldenrod;
  width: 150px;
  height: 54px;
}

.btn-pad {
  padding-top: 20px;
}

.panel2 {
  background: url("/uploads/wbp-content/wpb-background2.jpg") no-repeat center;
  background-size: cover;
  width: 50%;
  height: 285px;
}

@media screen and (max-width: 1550px) {
  .app {
    padding: 0 160px 10px 160px;
  }
}

@media screen and (max-width: 1260px) {
  .app {
    padding: 0 60px 10px 60px;
  }
}

@media screen and (max-width: 925px) {
  .app {
    padding: 0 30px 10px 30px;
  }
}

@media screen and (max-width: 880px) {
  .app {
    padding: 0;
    display: unset;
  }

  .page {
    padding: 30px 10px 30px 10px;
  }

  .entry-content {
    display: unset;
  }

  .left-container {
    max-width: 100%;
  }

  .right-container {
    max-width: 100%;
    padding: 0;
  }
}
</style>
