import api from "../api";

class ServicesService {
  async getAll() {
    return api.get("/DashBoard/AllServices");
  }
  get(categ1, categ2) {
    return api.get(`/DashBoard/Services?categ1=${categ1}&&categ2=${categ2}`);
  }
  async create(data) {
    return api.post("/DashBoard/Services/", data);
  }
  async delete(id) {
    return api.delete(`/DashBoard/Services/${id}`);
   }
}

export default new ServicesService();