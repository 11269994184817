<template>
  <div class="q-pa-md">
    <div class="srv_header_container">
      <div class="srv_add_btn">
        <q-btn
          class="srv_btn_style"
          color="light-blue-9"
          label="Add"
          icon="add"
          @click="onAddClick"
        />
      </div>
      <div class="srv_search">
        <q-input
          class="srv_search_style"
          bg-color="white"
          rounded
          outlined
          v-model="SearchText"
          label="Search"
        />
      </div>
    </div>
    <q-table
      title="Services"
      :loading="loading"
      :rows="rows"
      :columns="columns"
      row-key="id"
      @row-click="onRowClick"
      :rows-per-page-options="[10]"
      :filter="SearchText"
    >
      <template #loading>
        <q-inner-loading showing color="primary" />
      </template>
    </q-table>
  </div>

  <q-dialog
    v-model="dialogvalue"
    transition-show="scale"
    transition-hide="scale"
    no-esc-dismiss
    no-backdrop-dismiss
    maximized
  >
    <q-card class="srv_card">
      <div class="srv_master_container">
        <div style="width: 50%">
          <img
            v-if="ImageActive"
            class="srv_img"
            :key="selectedItem.rowId"
            :src="imgsrc"
          />
          <img
            v-if="!ImageActive"
            class="srv_img"
            :src="'/uploads/wbp-content/noImg.png'"
          />
        </div>
        <q-btn
          v-close-popup
          push
          color="red-5"
          round
          icon="close"
          class="absolute"
          style="z-index: 99; top: 0%"
          @click="fetchServices()"
        />
        <div class="srv_top_right_container">
          <div class="srv_main_data">
            <div class="srv_id_container">
              <q-input
                class="srv_id_input"
                outlined
                v-model.number="selectedItem.id"
                label="Id"
                stack-label
                type="number"
                disable
                readonly
              />
            </div>
            <div class="srv_categ1_container">
              <q-select
                class="srv_categ1_select"
                label="Category 1"
                transition-show="scale"
                transition-hide="scale"
                outlined
                map-options
                v-model="selectedItem.categ1"
                :options="categ1"
                @update:model-value="(val) => onCateg1Change(val.value)"
                required
                stack-label
              />
            </div>
            <div class="srv_categ2_container">
              <q-select
                class="srv_categ2_select"
                label="Category 2"
                transition-show="scale"
                transition-hide="scale"
                outlined
                map-options
                v-model="selectedItem.categ2"
                :options="this.categ2"
                @update:model-value="(val) => onCateg2Change(val.value)"
                required
                stack-label
              />
            </div>
          </div>
          <div class="srv_title_container">
            <q-input
              class="srv_title_input"
              outlined
              v-model="selectedItem.title"
              label="Title GR"
              maxlength="128"
              stack-label
            />
          </div>
          <div class="srv_title_container">
            <q-input
              class="srv_title_input"
              outlined
              v-model="selectedItem.title_en"
              label="Title EN"
              maxlength="128"
              stack-label
            />
          </div>
          <div class="srv_insert_data">
            <div class="srv_insertuser_container">
              <q-input
                outlined
                v-model="selectedItem.insertUser"
                label="Insert User"
                disable
                readonly
                stack-label
                class="srv_insertuser_input"
              />
            </div>
            <div class="srv_insdate_container">
              <q-input
                class="srv_insdate_input"
                outlined
                v-model="selectedItem.insDate"
                label="Insert Date"
                mask="date"
                disable
                readonly
                stack-label
              />
            </div>
          </div>
          <div class="srv_update_data">
            <div class="srv_updateuser_container">
              <q-input
                class="srv_updateuser_input"
                outlined
                v-model="selectedItem.updateUser"
                label="Update User"
                disable
                readonly
                stack-label
              />
            </div>
            <div class="srv_upddate_container">
              <q-input
                class="srv_upddate_input"
                outlined
                v-model="selectedItem.updDate"
                label="Update Date"
                mask="date"
                disable
                readonly
                stack-label
              />
            </div>
          </div>
        </div>
      </div>
      <q-card-section>
        <q-btn
          class="bg-teal text-white"
          push
          label="Upload"
          icon="file_upload"
          @click="onPickFile"
        />
        <input
          class="srv_fileinput"
          ref="fileInput"
          type="file"
          accept="image/*"
          @change="onFilePicked"
        />
      </q-card-section>
      <q-card-section>
        <div class="srv_desc_title">Description GR</div>
        <div class="row no-wrap items-center">
          <q-editor
            class="srv_editor"
            v-model="this.text"
            :model-value="this.text"
            :dense="$q.screen.lt.md"
            :toolbar="[
              [
                {
                  label: $q.lang.editor.align,
                  icon: $q.iconSet.editor.align,
                  fixedLabel: true,
                  options: ['left', 'center', 'right', 'justify'],
                },
              ],
              ['bold', 'italic', 'strike', 'underline'],
              ['token', 'hr', 'link', 'custom_btn'],
              [
                {
                  label: $q.lang.editor.fontSize,
                  icon: $q.iconSet.editor.fontSize,
                  fixedLabel: true,
                  fixedIcon: true,
                  list: 'no-icons',
                  options: [
                    'size-1',
                    'size-2',
                    'size-3',
                    'size-4',
                    'size-5',
                    'size-6',
                    'size-7',
                  ],
                },
              ],
              ['unordered', 'ordered'],
              ['undo', 'redo'],
            ]"
          />
        </div>
      </q-card-section>
      <q-card-section>
        <div class="srv_desc_title">Description EN</div>
        <div class="row no-wrap items-center">
          <q-editor
            class="srv_editor"
            v-model="this.text_en"
            :model-value="this.text_en"
            :dense="$q.screen.lt.md"
            :toolbar="[
              [
                {
                  label: $q.lang.editor.align,
                  icon: $q.iconSet.editor.align,
                  fixedLabel: true,
                  options: ['left', 'center', 'right', 'justify'],
                },
              ],
              ['bold', 'italic', 'strike', 'underline'],
              ['token', 'hr', 'link', 'custom_btn'],
              [
                {
                  label: $q.lang.editor.fontSize,
                  icon: $q.iconSet.editor.fontSize,
                  fixedLabel: true,
                  fixedIcon: true,
                  list: 'no-icons',
                  options: [
                    'size-1',
                    'size-2',
                    'size-3',
                    'size-4',
                    'size-5',
                    'size-6',
                    'size-7',
                  ],
                },
              ],
              ['unordered', 'ordered'],
              ['undo', 'redo'],
            ]"
          />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions class="srv_card_actions">
        <q-btn
          v-close-popup
          push
          color="light-blue-9"
          label="Submit"
          icon="done"
          @click="onSubmit"
        />
        <q-btn
          v-close-popup
          push
          color="red"
          label="Delete"
          icon="delete"
          @click="onDelete"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import servicesService from "../../services/servicesService";
import { ref } from "vue";

export default {
  name: "Blog",
  setup() {
    const rows = ref([]);
    const loading = ref(false);
    const ImageActive = ref(false);
    const selectedItem = ref({});
    const imgChange = ref("");
    const text = ref("");
    const text_en = ref("");
    const imgsrc = ref("");
    const fileExt = ref("");
    const categ2 = ref([]);
    const SearchText = ref("");
    const dialogvalue = ref(false);
    return {
      columns: [
        {
          name: "id",
          align: "left",
          label: "Id",
          field: "id",
          sortable: true,
        },
        {
          name: "title",
          align: "left",
          label: "Title",
          field: "title",
          sortable: true,
        },
      ],
      categ1: [
        {
          label: "Μελέτες",
          value: 1,
        },
        {
          label: "Κατασκευές",
          value: 2,
        },
      ],
      categ21: [
        {
          label: "Αρχιτεκτονική μελέτη και επίβλεψη νέων κατασκευών",
          value: 1,
        },
        {
          label: "Στατικές μελέτες",
          value: 2,
        },
        {
          label: "Μελέτες ενεργειακής απόδοσης",
          value: 3,
        },
        {
          label: "Νομιμοποιήσεις αυθαιρέτων",
          value: 4,
        },
        {
          label: "Πιστοποιητικά ενεργειακής απόδοσης (ΠΕΑ)",
          value: 5,
        },
        {
          label: "Τοπογραφικά",
          value: 6,
        },
      ],
      categ22: [
        {
          label: "Κατασκευές ιδιωτικών έργων",
          value: 1,
        },
        {
          label: "Κατασκευές Δημοσίων έργων",
          value: 2,
        },
        {
          label: "Ανακαινίσεις",
          value: 3,
        },
      ],
      rows,
      loading,
      ImageActive,
      selectedItem,
      imgChange,
      text,
      text_en,
      imgsrc,
      fileExt,
      SearchText,
      dialogvalue,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.fetchServices();
  },
  created() {},
  methods: {
    async fetchServices() {
      this.loading = true;
      servicesService
        .getAll()
        .then((response) => {
          this.rows = response.data;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCateg1Change(value) {
      this.selectedItem.categ1 = value;
      this.selectedItem.categ2 = null;
      if (value == 1) {
        this.categ2 = this.categ21;
      } else if (value == 2) {
        this.categ2 = this.categ22;
      } else {
        this.categ2 = null;
      }
    },
    onCateg2Change(value) {
      this.selectedItem.categ2 = value;
    },
    onCateg1Add(value) {
      if (value == 1) {
        this.categ2 = this.categ21;
      } else if (value == 2) {
        this.categ2 = this.categ22;
      } else {
        this.categ2 = null;
      }
    },
    onRowClick(evt, row) {
      this.selectedItem = row;
      this.imgsrc = row.image;
      this.text = row.text;
      this.text_en = row.text_en;
      const imgactive = this.imgsrc;
      if (imgactive != null && imgactive.length > 0) {
        this.ImageActive = true;
      } else {
        this.ImageActive = false;
      }
      this.imgChange = null;
      this.onCateg1Add(this.selectedItem.categ1);
      this.dialogvalue = true;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file!");
      }
      this.fileExt = filename.split(".").pop();
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.addEventListener("load", () => {
        this.imgChange = fileReader.result;
        this.imgsrc = fileReader.result;
        this.ImageActive = true;
      });
    },
    onSubmit() {
      if (this.imgChange != null && this.imgChange != "") {
        this.selectedItem.imagebytes = this.imgChange.substr(
          this.imgChange.lastIndexOf("base64,") + 7,
          this.imgChange.length
        );
        this.selectedItem.image_ext = this.fileExt;
      } else {
        this.selectedItem.imagebytes = null;
        this.selectedItem.image_ext = null;
      }
      this.selectedItem.text = this.text;
      this.selectedItem.text_en = this.text_en;

      servicesService
        .create(this.selectedItem)
        .then((response) => {})
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
          this.fetchServices();
        });
    },
    onAddClick() {
      this.selectedItem = ref({});
      this.text = ref("");
      this.text_en = ref("");
      this.imgChange = ref("");
      this.dialogvalue = true;
      this.imgsrc = ref("");
      this.categ2 = null;
      this.ImageActive = false;
    },
    onDelete() {
      this.loading = true;
      servicesService
        .delete(this.selectedItem.id)
        .then((response) => {})
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
          this.fetchServices();
        });
    },
  },
};
</script>
<style scoped>
.srv_header_container {
  display: flex;
  padding: 10px 0 10px;
  width: 100%;
}
.srv_add_btn {
  display: flex;
  width: 50%;
  align-items: center;
}
.srv_btn_style {
  height: 30px;
}
.srv_search {
  display: flex;
  width: 50%;
}
.srv_search_style {
  width: 300px;
  margin-left: auto;
  margin-right: 0;
}
.srv_card {
  width: 1100px;
  height: 95%;
  background-color: #f5f5f5;
}
.srv_master_container {
  display: flex;
  width: 100%;
}
.srv_img {
  height: 542px;
  width: 100%;
}
.srv_top_right_container {
  display: unset;
  padding: 10px 10px 0;
  width: 50%;
}
.srv_main_data {
  width: 100%;
}
.srv_id_container {
  width: 50%;
}
.srv_id_input {
  width: 120px;
  background-color: white;
}
.srv_categ2_container,
.srv_categ1_container {
  padding-top: 20px;
  width: 100%;
}
.srv_categ2_select,
.srv_categ1_select {
  width: 100%;
  background-color: white;
}
.srv_title_container {
  width: 100%;
  padding-top: 20px;
}
.srv_title_input {
  width: 100%;
  background-color: white;
}
.srv_insert_data {
  width: 100%;
  padding-top: 40px;
  display: flex;
}
.srv_insertuser_container {
  width: 50%;
}
.srv_insertuser_input {
  width: 100%;
  background-color: white;
}
.srv_insdate_container {
  width: 50%;
  padding-left: 25px;
}
.srv_insdate_input {
  width: 100%;
  background-color: white;
}
.srv_update_data {
  width: 100%;
  padding-top: 20px;
  display: flex;
}
.srv_updateuser_container {
  width: 50%;
}
.srv_updateuser_input {
  width: 100%;
  background-color: white;
}
.srv_upddate_container {
  width: 50%;
  padding-left: 25px;
}
.srv_upddate_input {
  width: 100%;
  background-color: white;
}
.srv_fileinput {
  display: none;
}
.srv_desc_title {
  text-align: center;
  font-size: 20px;
}
.srv_editor {
  width: 100%;
}
.srv_card_actions {
  justify-content: right;
}
@media screen and (max-width: 1100px) {
  .srv_card {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 620px) {
  .srv_img {
    height: 100%;
  }
  .srv_insert_data,
  .srv_update_data {
    display: unset;
  }
  .srv_categ1_container,
  .srv_title_container,
  .srv_insertuser_container,
  .srv_insdate_container,
  .srv_updateuser_container,
  .srv_upddate_container {
    width: 100%;
    padding-top: 15px;
    padding-left: 0px;
  }
}
</style>
