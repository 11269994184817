import MainPage from '../components/MainPage.vue'
import Company from '../components/Company.vue'
import Services from '../components/Services.vue'
import Service from '../components/Service.vue'
import Contact from '../components/Contact.vue'
import Blogs from '../components/Blogs.vue'
import Blog from '../components/Blog.vue'
import DashBoard from '../components/Dashboard/Dashboard'
import Login from '../components/Dashboard/Login'
import PageNotFound from '../components/PageNotFound'
import accessService from "../services/accessService";
import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from "vuex";

const routes = [
    {
      path: '/',
      name: 'MainPage',
      component: MainPage,
      meta: {
        title: 'Αρχική σελίδα - Mi Architecture'
      }
    },
    {
        path: '/DashBoard',
        name:'DashBoard',
        component: DashBoard,
        meta: {
          title: 'DashBoard',
        },
        beforeEnter: (to, from, next) => {
            accessService
            .get()
            .then((response) => {
              next();
            })
            .catch((e) => {
             next("/Login");
             })
             .finally(() => {
             });
        }
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Login',
      },
      beforeEnter: requireAuth
    },
    {
      path: '/Company',
      name:'Company',
      component: Company,
      meta: {
        title: 'Εταιρεία - Mi Architecture',
      }
    },
    {
      title: 'Υπηρεσίες',
      path: '/Services',
      name:'Services',
      component: Services,
      meta: {
        title: 'Υπηρεσίες - Mi Architecture',
      }
    },
    {
      path: '/Services/:routername',
      name:'Service',
      component: Service,
      meta: {
        title: 'Υπηρεσίες - Mi Architecture',
      }
    },
    {
      path: '/Contact',
      name:'Contact',
      component: Contact,
      meta: {
        title: 'Επικοινωνία - Mi Architecture',
      }
    },
    {
      title: 'Blogs',
      path: '/Blogs',
      name:'Blogs',
      component: Blogs,
      meta: {
        title: 'Blog - Mi Architecture',
      }
    },
    {
      title: 'Blogs',
      path: '/Blogs/:routername',
      name:'Blog',
      component: Blog,
      meta: {
        title: 'Blog - Mi Architecture',
      }
    },
    {
      name: 'PageNotFound',
      path: '/:pathMatch(.*)*', 
      component: PageNotFound ,
      meta: {
        title: 'Page Not Found - Mi Architecture',
      }
    }
  ]
  const router = createRouter({
    history: createWebHistory(),
    routes,
    mounted () {
      window.scrollTo(0, 0)
    }
  })

  function requireAuth(to, from, next) {
    accessService
        .get()
        .then((response) => {
          next("/DashBoard");
        })
        .catch((e) => {
          next();
        })
        .finally(() => {
        }); 
  }
  export default router